var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('div',[_c('form',{staticClass:"form",on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.formOnsubmit()}}},[_c('div',{staticClass:"pb-5",attrs:{"data-wizard-type":"step-content","data-wizard-state":"current"}},[_c('h5',{staticClass:"font-weight-bolder text-dark font-size-h6 font-size-h3-lg"},[_c('span',[_vm._v(" "+_vm._s(_vm.title)+" ")])]),_c('b-form-group',{attrs:{"id":"input-group-name","label":"Nama:","label-for":"input-name"}},[_c('b-form-input',{attrs:{"id":"input-name","placeholder":"Nama"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.error.name))])],1),_c('b-form-group',{attrs:{"id":"input-group-birt-date","label":"Tanggal Lahir:","label-for":"input-birt-date"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('b-form-input',_vm._g(_vm._b({attrs:{"id":"input-birt-date","label":"Tanggal Lahir:","readonly":""},model:{value:(_vm.form.birt_date),callback:function ($$v) {_vm.$set(_vm.form, "birt_date", $$v)},expression:"form.birt_date"}},'b-form-input',attrs,false),on))]}}]),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c('v-date-picker',{on:{"input":function($event){_vm.menu2 = false}},model:{value:(_vm.form.birt_date),callback:function ($$v) {_vm.$set(_vm.form, "birt_date", $$v)},expression:"form.birt_date"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.error.birt_date))])],1),_c('b-form-group',{attrs:{"label":"Jenis Kelamin"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ariaDescribedby = ref.ariaDescribedby;
return [_c('b-form-radio-group',{attrs:{"id":"radio-group-gender","aria-describedby":ariaDescribedby,"name":"radio-sub-component"},model:{value:(_vm.form.gender),callback:function ($$v) {_vm.$set(_vm.form, "gender", $$v)},expression:"form.gender"}},[_c('b-form-radio',{attrs:{"value":"male"}},[_vm._v("Laki-laki")]),_c('b-form-radio',{attrs:{"value":"female"}},[_vm._v("Perempuan")])],1)]}}])}),_c('b-form-group',{attrs:{"id":"input-group-registration-number","label":"NIK:","label-for":"input-registration-number"}},[_c('b-form-input',{attrs:{"id":"input-registration-number","placeholder":"Nomor Induk Kependudukan"},model:{value:(_vm.form.registration_number),callback:function ($$v) {_vm.$set(_vm.form, "registration_number", $$v)},expression:"form.registration_number"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.error.registration_number))])],1),_c('b-form-group',{attrs:{"id":"input-group-phone","label":"No. Telepon:","label-for":"input-phone"}},[_c('b-form-input',{attrs:{"id":"input-phone","placeholder":"Nomor Telepon"},model:{value:(_vm.form.phone),callback:function ($$v) {_vm.$set(_vm.form, "phone", $$v)},expression:"form.phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.error.phone))])],1),_c('b-form-group',{attrs:{"id":"input-group-password"}},[(_vm.purpose != 'edit')?_c('label',{attrs:{"for":"input-password"}},[_vm._v("Password:")]):_vm._e(),(_vm.purpose == 'edit')?_c('label',{attrs:{"for":"input-password"}},[_vm._v("Ubah Password: "),_c('em',{staticClass:"text-muted"},[_vm._v("opsional")])]):_vm._e(),_c('b-input-group',{scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('b-input-group-text',{on:{"click":function($event){_vm.passwordIsVisible == 'password'
                    ? (_vm.passwordIsVisible = 'text')
                    : (_vm.passwordIsVisible = 'password')}}},[(_vm.passwordIsVisible == 'password')?_c('div',[_c('b-icon-eye-slash')],1):_vm._e(),(_vm.passwordIsVisible == 'text')?_c('div',[_c('b-icon-eye')],1):_vm._e()])]},proxy:true}])},[_c('b-form-input',{attrs:{"id":"input-password","placeholder":_vm.purpose == 'edit' ?  'Masukkan Password Baru:' : 'Password:',"type":_vm.passwordIsVisible},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.error.password))])],1),_c('b-form-group',{attrs:{"id":"input-group-role","label":"Jabatan:","label-for":"input-role"}},[_c('treeselect',{attrs:{"multiple":false,"placeholder":"Pilih Jabatan","options":_vm.roles},model:{value:(_vm.form.role_id),callback:function ($$v) {_vm.$set(_vm.form, "role_id", $$v)},expression:"form.role_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.error.role_id))])],1),_c('b-form-group',{attrs:{"id":"input-group-address"}},[_c('label',{attrs:{"for":"input-address"}},[_vm._v("Alamat Rumah: "),_c('em',{staticClass:"text-muted"},[_vm._v("opsional")])]),_c('b-form-textarea',{attrs:{"id":"input-address","placeholder":"Alamat Rumah","rows":"4","max-rows":"8"},model:{value:(_vm.form.address),callback:function ($$v) {_vm.$set(_vm.form, "address", $$v)},expression:"form.address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.error.address))])],1)],1),_c('div',{staticClass:"d-flex justify-content-between border-top pt-10"},[_c('div',[_c('b-button',{attrs:{"type":"submit","variant":"primary"}},[_vm._v("Simpan")]),_c('b-button',{staticClass:"ml-2",attrs:{"type":"button","variant":"default"},on:{"click":function($event){return _vm.$router.push('/staffs')}}},[_vm._v(" Batal ")])],1)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }