<template>
<v-app>
  <div>
    <form class="form" @submit.stop.prevent="formOnsubmit()">
      <!--begin: Wizard Step 1-->
      <div
        class="pb-5"
        data-wizard-type="step-content"
        data-wizard-state="current"
      >
        <h5 class="font-weight-bolder text-dark font-size-h6 font-size-h3-lg">
          <span>
            {{ title }}
          </span>
        </h5>

        <b-form-group
          id="input-group-name"
          label="Nama:"
          label-for="input-name"
        >
          <b-form-input
            id="input-name"
            v-model="form.name"
            placeholder="Nama"
          ></b-form-input>
          <small class="text-danger">{{ error.name }}</small>
        </b-form-group>

        <b-form-group
            id="input-group-birt-date"
            label="Tanggal Lahir:"
            label-for="input-birt-date"
          >
            <v-menu
              v-model="menu2"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <b-form-input
                  id="input-birt-date"
                  v-model="form.birt_date"
                  label="Tanggal Lahir:"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></b-form-input>
              </template>
              <v-date-picker
                v-model="form.birt_date"
                @input="menu2 = false"
              ></v-date-picker>
            </v-menu>
            <small class="text-danger">{{ error.birt_date }}</small>
          </b-form-group>

          <b-form-group label="Jenis Kelamin" v-slot="{ ariaDescribedby }">
            <b-form-radio-group
              id="radio-group-gender"
              v-model="form.gender"
              :aria-describedby="ariaDescribedby"
              name="radio-sub-component"
            >
              <b-form-radio value="male">Laki-laki</b-form-radio>
              <b-form-radio value="female">Perempuan</b-form-radio>
            </b-form-radio-group>
          </b-form-group>

        <b-form-group
          id="input-group-registration-number"
          label="NIK:"
          label-for="input-registration-number"
        >
          <b-form-input
            id="input-registration-number"
            v-model="form.registration_number"
            placeholder="Nomor Induk Kependudukan"
          ></b-form-input>
          <small class="text-danger">{{ error.registration_number }}</small>
        </b-form-group>

        <b-form-group
          id="input-group-phone"
          label="No. Telepon:"
          label-for="input-phone"
        >
          <b-form-input
            id="input-phone"
            v-model="form.phone"
            placeholder="Nomor Telepon"
          ></b-form-input>
          <small class="text-danger">{{ error.phone }}</small>
        </b-form-group>

        <!-- Text input -->
        <b-form-group
          id="input-group-password"
        >
        <label for="input-password" v-if="purpose != 'edit'">Password:</label>
        <label for="input-password" v-if="purpose == 'edit'">Ubah Password: <em class="text-muted">opsional</em></label>
          <b-input-group>
            <template #prepend>
              <b-input-group-text
                @click="
                  passwordIsVisible == 'password'
                    ? (passwordIsVisible = 'text')
                    : (passwordIsVisible = 'password')
                "
              >
                <div v-if="passwordIsVisible == 'password'">
                  <b-icon-eye-slash></b-icon-eye-slash>
                </div>
                <div v-if="passwordIsVisible == 'text'">
                  <b-icon-eye></b-icon-eye>
                </div>
              </b-input-group-text>
            </template>
            <b-form-input
              id="input-password"
              v-model="form.password"
              :placeholder="purpose == 'edit' ?  'Masukkan Password Baru:' : 'Password:'"
              :type="passwordIsVisible"
            ></b-form-input>
          </b-input-group>
          <small class="text-danger">{{ error.password }}</small>
        </b-form-group>

        <b-form-group
          id="input-group-role"
          label="Jabatan:"
          label-for="input-role"
        >
          <treeselect
            v-model="form.role_id"
            :multiple="false"
            placeholder="Pilih Jabatan"
            :options="roles"
          />
          <small class="text-danger">{{ error.role_id }}</small>
        </b-form-group>

        <!-- <b-form-group
          id="input-group-posyandu"
          label="Posyandu:"
          label-for="input-posyandu"
        >
          <treeselect
            v-model="form.posyandu_id"
            :multiple="false"
            placeholder="Pilih Posyandu"
            :options="posyandu"
          />
          <small class="text-danger">{{ error.posyandu_id }}</small>
        </b-form-group> -->

        <b-form-group id="input-group-address">
          <label for="input-address">Alamat Rumah: <em class="text-muted">opsional</em></label>
          <b-form-textarea
            id="input-address"
            v-model="form.address"
            placeholder="Alamat Rumah"
            rows="4"
            max-rows="8"
          ></b-form-textarea>
          <small class="text-danger">{{ error.address }}</small>
        </b-form-group>
      </div>
      <!--end: Wizard Step 1-->

      <!--begin: Wizard Actions -->
      <div class="d-flex justify-content-between border-top pt-10">
        <div>
          <b-button type="submit" variant="primary">Simpan</b-button>
          <b-button
            type="button"
            class="ml-2"
            variant="default"
            @click="$router.push('/staffs')"
          >
            Batal
          </b-button>
        </div>
      </div>
      <!--end: Wizard Actions -->
    </form>
  </div>
</v-app>
</template>

<script>
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

export default {
  props: {
    form: Object,
    route: String,
    title: String,
    purpose: String
  },
  data() {
    return {
      error: {
        name: "",
        email: "",
        password: "",
        phone: "",
        registration_number: "",
        role_id: "",
        address: "",
        posyandu_id: "",
      },
      // other
      passwordIsVisible: "password",
      roles: [],
      posyandu: [],
      menu2: false,
    };
  },
  methods: {
    async getRoleOption() {
      let response = await module.setTreeSelect("api/roles/select/staff");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.roles = response.data;
        this.roles.unshift({
          label: "Pilih Jabatan",
          id: "",
          isDisabled: true,
        });
      }
    },
    
    async getPosyanduOption() {
      let response = await module.setTreeSelect("api/posyandu");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.posyandu = response.data;
        this.posyandu.unshift({
          label: "Pilih Posyandu",
          id: "",
          isDisabled: true,
        });
      }
    },

    async formOnsubmit() {
      this.form.email = this.form.registration_number
      // Make Request
      let response = await module.submit(this.form, this.route);
      // Check Response
      if (response.state == "error") {
        // Validation Error
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );
      } else {
        // Success
        Swal.fire(response.success.title, response.success.message, "success");
        this.$router.push('/staffs')
      }
    },
  },
  mounted(){
    this.getRoleOption()
    this.getPosyanduOption()
  }
};
</script>